import Img, { FluidObject, GatsbyImageFluidProps } from 'gatsby-image'
import React, { useState, useEffect } from 'react'
import useIntersectionObserver from 'src/hooks/useIntersectionObserver'
import { AppTheme } from 'src/styles/theme'
import styled from 'styled-components'
import {
  SpaceProps,
  LayoutProps,
  space,
  layout,
  PositionProps,
  position,
  BorderProps,
  border
} from 'styled-system'
import { ContentWrapper } from './ContentWrapper'

export const ModifiedImg: React.FC<Omit<
  ModifiedImgProps,
  'propTypes' | 'ref'
>> = styled(Img)`
  ${border}
  ${space};
  ${position}
  ${layout}
`

type ModifiedImgProps = GatsbyImageFluidProps &
  SpaceProps<AppTheme> &
  LayoutProps<AppTheme> &
  PositionProps<AppTheme> &
  BorderProps<AppTheme>

export const ModifiedImgWithAni: React.FC<Omit<
  ModifiedImgProps,
  'propTypes' | 'ref'
>> = styled(Img)`
  animation: kenburns-top 10s ease-out both;
  @keyframes kenburns-top {
    0% {
      transform: scale(1) translateY(0);
      -webkit-transform-origin: 30% 30%;
      transform-origin: 30% 30%;
    }
    100% {
      transform: scale(1.2) translateY(-50px);
      -webkit-transform-origin: top;
      transform-origin: top;
    }
  }
  ${border}
  ${space};
  ${position}
  ${layout}
`

interface ModifiedImgWithAnimationProps {
  image: GatsbyImageFluidProps
}

const ModifiedImgWithAnimation: React.FC<ModifiedImgWithAnimationProps> = ({
  image
}) => {
  const [ref, { entry }] = useIntersectionObserver()
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(current => {
      if (current) return current
      return entry?.isIntersecting ?? false
    })
  }, [entry?.isIntersecting])

  return (
    <ContentWrapper ref={ref} overflow="hidden">
      {isVisible && (
        <ModifiedImgWithAni
          width={['100%', '70%']}
          height={['25rem', '40rem']}
          fluid={image.fluid}
        />
      )}
    </ContentWrapper>
  )
}

export default ModifiedImgWithAnimation
