import React from 'react'
import styled from 'styled-components'
import GatsbyBackgroundImage from 'gatsby-background-image'
import { graphql, useStaticQuery } from 'gatsby'
import { LearnMore } from './LearnMore'
import Title from './Title'
import Paragraph from './Paragraph'
import { ContentWrapper } from './ContentWrapper'
import { Logo, Logo2, LogoTop } from './Logo'
import { useDataContext } from 'src/contexts/dataContext'
import { useScreenSize } from 'src/hooks/useScreenSize'

const query = graphql`
  query {
    noi: file(relativePath: { eq: "noi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fullBiancoNero: file(relativePath: { eq: "fullBiancoNero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const BackgroundImage = styled(GatsbyBackgroundImage)`
  display: flex;
  z-index: 10;
  width: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`
const TitleWithAnimation = styled(Title)`
  -webkit-animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 1s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(12px);
      filter: blur(12px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
`

const ParagraphWithAnimation = styled(Paragraph)`
  -webkit-animation: text-focus-in 2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  animation: text-focus-in 2s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
  @keyframes text-focus-in {
    0% {
      -webkit-filter: blur(8px);
      filter: blur(8px);
      opacity: 0;
    }
    100% {
      -webkit-filter: blur(0px);
      filter: blur(0px);
      opacity: 1;
    }
  }
`

const Landing: React.FC = ({ children }) => {
  const { noi } = useStaticQuery(query)
  const { language } = useDataContext()
  const { screenSize } = useScreenSize()
  const isMobile = screenSize === 'small' || screenSize === 'medium'

  return (
    <BackgroundImage fluid={noi.childImageSharp.fluid}>
      <ContentWrapper flexDirection="row">
        {/* <TitleWithAnimation fontSize={[4, 7]} color="negative">
          I CALANCHI
        </TitleWithAnimation> */}

        {!isMobile ? <Logo /> : <Logo2 />}

        <ContentWrapper
          marginTop={['2rem', '4rem']}
          marginLeft={['0rem', '2rem']}
          flexDirection="column"
        >
          <ParagraphWithAnimation
            fontSize={[1, 4]}
            color="primary"
            paddingLeft="1"
            m="0"
          >
            {language === 'ITA' ? 'Autosufficienza' : 'Self-sufficiency'}
          </ParagraphWithAnimation>
          <ParagraphWithAnimation
            fontSize={[1, 4]}
            color="primary"
            paddingLeft="1"
            m="0"
          >
            {language === 'ITA' ? "Semplicita'" : "Simplicity'"}
          </ParagraphWithAnimation>
          <ParagraphWithAnimation
            fontSize={[1, 4]}
            color="primary"
            paddingLeft="1"
            m="0"
          >
            {language === 'ITA' ? "Sostenibilita''" : "Sustainability'"}
          </ParagraphWithAnimation>
        </ContentWrapper>

        <LearnMore />
        {children}
      </ContentWrapper>
    </BackgroundImage>
  )
}

export default Landing
