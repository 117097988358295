import { IconDefinition } from '@fortawesome/fontawesome-free-regular'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faLeaf } from '@fortawesome/free-solid-svg-icons'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps
} from '@fortawesome/react-fontawesome'
import React from 'react'
import { ContentWrapper } from './ContentWrapper'
import Paragraph from './Paragraph'

interface TitleOfContentProps {
  title?: string
  icon?: IconProp
  id?: string
}

const TitleOfContent = ({ title, icon, id }: TitleOfContentProps) => {
  return (
    <>
      <ContentWrapper
        height={['18rem', 'auto']}
        id={id}
        marginTop={['3rem', '4rem']}
        flexDirection={['column', 'column']}
      >
        <FontAwesomeIcon size="4x" icon={icon} />

        <Paragraph
          fontFamily="titleFont"
          width={['100%', '50%']}
          fontSize={[3, 5]}
        >
          <em>{title}</em>
        </Paragraph>
      </ContentWrapper>
    </>
  )
}

export default TitleOfContent
