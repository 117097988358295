import React, { HTMLProps } from 'react'
import { AppTheme } from 'src/styles/theme'

import styled from 'styled-components'
import {
  TypographyProps,
  typography,
  LayoutProps,
  layout,
  SpaceProps,
  space,
  ColorProps,
  color,
  FlexboxProps,
  flexbox
  // TextColorProps
} from 'styled-system'

type H1Props = HTMLProps<HTMLHeadElement> &
  TypographyProps<AppTheme> &
  LayoutProps<AppTheme> &
  SpaceProps<AppTheme> &
  ColorProps &
  FlexboxProps

const Title: React.FC<H1Props> = styled.h1`
  ${typography};
  ${layout};
  ${color}
  ${space}
  ${flexbox}
`

type TitleWithFontSizeResponseProps = H1Props
const TitleWithFontSizeResponsive: React.FC<TitleWithFontSizeResponseProps> = ({
  children,
  fontSize = [3, 4],
  ...props
}) => {
  return (
    <Title fontSize={fontSize} {...props}>
      {children}
    </Title>
  )
}

export default TitleWithFontSizeResponsive
