import React from 'react'
import { useScreenSize } from 'src/hooks/useScreenSize'
import { ContentWrapper } from './ContentWrapper'

type SpaceProps = {
  heigth: string
}

const Space = (heigth: SpaceProps) => {
  const { screenSize } = useScreenSize()
  const islargeOrXlarge = screenSize === 'large' || 'x-large'
  return islargeOrXlarge && <ContentWrapper height={heigth}></ContentWrapper>
}

export default Space
