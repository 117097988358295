import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { graphql, useStaticQuery } from 'gatsby'
import React, { HTMLProps, useRef } from 'react'

import { useDataContext } from 'src/contexts/dataContext'
import { useOnClickOutside } from 'src/hooks/useOnClickOutside'
import { useScreenSize } from 'src/hooks/useScreenSize'
import styled from 'styled-components'
import Container from './Container'

import { ContentWrapper } from './ContentWrapper'
import Paragraph from './Paragraph'
import Title from './Title'

interface ShowMenuProps {
  isMobile: boolean
}
const ShowMenu = styled.div<ShowMenuProps>`
  width: 100%;
  height: ${props => (props.isMobile ? 'auto' : 'auto')};
  /* max-height: '100%'; */
  display: ${props => (props.isMobile ? 'block' : 'block')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* overflow-y: scroll; */
  position: fixed;
  z-index: 400;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props =>
    props.isMobile ? 'white' : ' rgba(0, 0, 0, 0.7)'};
  overflow-x: hidden;
  transition: all 2s ease-in;
  padding: ${props => (props.isMobile ? '0' : '10%')};
`

interface CloseProps {
  isOpen: boolean
  isMobile: boolean
}
const Close = styled(FontAwesomeIcon)<CloseProps>`
  position: absolute;
  z-index: 300;
  top: 0;
  color: ${props => (props.isMobile ? 'black' : 'white')};
  margin: ${props => (props.isOpen ? '1rem' : '3rem')};
  right: 0;
`

const query = graphql`
  query {
    allMarkdownRemark {
      nodes {
        html
      }
    }
  }
`
const MenuOverlay = () => {
  const { allMarkdownRemark } = useStaticQuery(query)
  const { getAllMenu, toggleMenu, showMenu, language } = useDataContext()
  const { screenSize } = useScreenSize()
  const isMobile = screenSize === 'small' || screenSize === 'medium'

  const refMenu = useRef()
  useOnClickOutside(refMenu, () => toggleMenu())
  console.log(allMarkdownRemark.nodes[0].html)
  const fonts = [2, 3]
  return (
    <ShowMenu isMobile={isMobile}>
      <ContentWrapper
        margin="0rem"
        paddingY={['4rem', '5rem']}
        paddingX={['1rem', '10rem']}
        width={['100%', '100%']}
        alignItems="flex-start"
        flexDirection="column"
        ref={refMenu}
        backgroundColor="primary"
      >
        <Close
          isMobile={isMobile}
          isOpen={showMenu}
          onClick={() => toggleMenu()}
          size="3x"
          icon={faTimes}
        />
        <Paragraph textAlign="left" fontSize={20} fontWeight="500">
          <div
            dangerouslySetInnerHTML={{
              __html: allMarkdownRemark.nodes[0].html
            }}
          />
        </Paragraph>
      </ContentWrapper>
    </ShowMenu>
  )
}

export default MenuOverlay
