import React from 'react'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { AppTheme } from 'src/styles/theme'
import styled from 'styled-components'
import {
  typography,
  space,
  color,
  TypographyProps,
  SpaceProps,
  ColorProps
} from 'styled-system'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ContentWrapper } from './ContentWrapper'
import Space from './Space'
import { useDataContext } from 'src/contexts/dataContext'

const StyledFooter = styled.footer`
  flex: 1 1 auto;
`

type ParagraphProps = TypographyProps<AppTheme> &
  SpaceProps<AppTheme> &
  ColorProps<AppTheme>

const P = styled.p<ParagraphProps>`
  ${typography}
  ${space}
  ${color}
`

const Footer = () => {
  const { language } = useDataContext()

  return (
    <>
      <Space heigth="5rem" />
      <ContentWrapper height="8rem" backgroundColor="autumnBlue">
        <StyledFooter>
          <P color="neutralDark" textAlign="center">
            {language === 'ITA'
              ? 'Seguici nei socials!'
              : 'Follow us on socials!'}
            {/* <FontAwesomeIcon icon={faInstagram} /> */}
          </P>
          <P fontSize={16} color="neutralDark" textAlign="center" marginX={4}>
            Web Development by Alessandro Tucci
          </P>
        </StyledFooter>
      </ContentWrapper>
    </>
  )
}

export { Footer }
