import React from 'react'
import { ContentWrapper } from './ContentWrapper'
import Paragraph from './Paragraph'

interface ArgumentProps {
  titleContent: string
  paragraphContent: string
  mainContent: string
  extraContent?: string
  cucinaContent?: string
  footerContent?: string
  extraFooterContent?: string
}

const Argument = ({
  titleContent,
  paragraphContent,
  mainContent,
  extraContent,
  cucinaContent,
  footerContent,
  extraFooterContent
}: ArgumentProps) => {
  return (
    <ContentWrapper
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="flex-start"
      backgroundColor="autumnBlue"
      width={['100%', '43rem']}
    >
      <ContentWrapper
        height={['auto', '55rem']}
        padding={['0.5rem ', '5rem']}
        paddingY={['2rem ', '5rem']}
        flexDirection="column"
        justifyContent="space-evenly"
        alignItems="flex-start"
      >
        <Paragraph
          color="neutralDark"
          width={['100%', '100%']}
          fontWeight="600"
          paddingX={[4, 0]}
          fontSize={[2, 3]}
          textAlign="left"
          margin="0 0 2rem 0"
        >
          {titleContent.toUpperCase()}
        </Paragraph>

        <Paragraph
          color="negative"
          fontSize={[4, 5]}
          fontWeight="700"
          textAlign="left"
          paddingX={[4, 0]}
          margin="0 0 2rem 0"
          width={['100%', '70%']}
        >
          <em>{paragraphContent}</em>
        </Paragraph>

        <Paragraph textAlign="left" fontSize={[3, 3]} paddingX={[4, 0]}>
          {mainContent}
          <br />

          {extraContent}
          <br />
          {cucinaContent}

          <br />
          {footerContent}
          <br />
          {extraFooterContent}
        </Paragraph>
      </ContentWrapper>
    </ContentWrapper>
  )
}

export default Argument
