import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { HTMLProps } from 'react'
import { AppTheme } from 'src/styles/theme'
import styled from 'styled-components'
import {
  FlexboxProps,
  SpaceProps,
  LayoutProps,
  ColorProps,
  flexbox,
  space,
  color
} from 'styled-system'
import { window } from 'browser-monads'
import { useDataContext } from 'src/contexts/dataContext'
import { useScreenSize } from 'src/hooks/useScreenSize'

type DivProps = HTMLProps<HTMLDivElement> &
  FlexboxProps<AppTheme> &
  SpaceProps<AppTheme> &
  LayoutProps<AppTheme> &
  ColorProps

const ModifiedIcon = styled(FontAwesomeIcon)`
  text-align: center;
  cursor: pointer;
  -webkit-animation: heartbeat 5s ease-in-out infinite both;
  animation: heartbeat 5s ease-in-out infinite both;

  @keyframes heartbeat {
    from {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    10% {
      -webkit-transform: scale(0.91);
      transform: scale(0.91);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    17% {
      -webkit-transform: scale(0.98);
      transform: scale(0.98);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    33% {
      -webkit-transform: scale(0.87);
      transform: scale(0.87);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    45% {
      -webkit-transform: scale(1);
      transform: scale(1);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
  }
`

interface ModifiedButtonProps {
  isMobile: boolean
}

const ModifiedButton = styled.button<ModifiedButtonProps>`
  color: #8d363f;
  font-weight: bold;
  background: #ffffff;
  text-transform: uppercase;
  cursor: pointer;
  margin-bottom: 3rem;
  padding: ${props => (props.isMobile ? '10px' : '20px')};
  border: 4px solid #8d363f;
  display: inline-block;
  transition: all 0.4s ease 0s;
  border-radius: ${props => (props.isMobile ? '10px' : '10px')};
  :hover {
    color: white !important;
    background: #8d363f;
    border-color: #ffffff !important;
    transition: all 0.4s ease 0s;
  }
`
const LearnMoreContainer: React.FC<DivProps> = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  bottom: 0;
  margin-bottom: 2rem;
  ${flexbox};
  ${space};
  ${color}
`
export const LearnMore: React.FC = () => {
  const { toggleMenu, language } = useDataContext()
  const { screenSize } = useScreenSize()
  const isMobile = screenSize === 'small' || screenSize === 'medium'
  const iconSize = isMobile ? '2x' : '3x'
  return (
    <LearnMoreContainer justifyContent="flex-end">
      <ModifiedButton isMobile={isMobile} onClick={() => toggleMenu()}>
        {language === 'ITA' ? 'GUARDA IL MENU' : 'VIEW THE MENU'}
      </ModifiedButton>
      <ModifiedIcon
        onClick={() => window.scrollTo({ top: 800, behavior: 'smooth' })}
        color="#8d363f"
        size={iconSize}
        icon={faArrowDown}
      />
    </LearnMoreContainer>
  )
}
