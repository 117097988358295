import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const AbsoluteFontAwesomeIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
  -webkit-animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 1.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  position: fixed;
  z-index: 300;
  bottom: 0;
  margin: 3rem;
  right: 0;
`

export default AbsoluteFontAwesomeIcon
