import React, { useState } from 'react'
import mapStyles from 'src/styles/mapStyles'
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow
} from '@react-google-maps/api'
import styled from 'styled-components'

const ModifiedButton = styled.button`
  color: black;
  font-weight: bold;
  background: transparent;
  text-transform: uppercase;
  position: absolute;
  margin: 1rem;
  padding: 10px;
  border: 4px solid black;
  transition: all 0.4s ease 0s;
  :hover {
    color: white !important;
    background: #8d363f;
    border-color: #ffffff !important;
    transition: all 0.4s ease 0s;
  }
`
const mapContainerStyle = {
  width: '100vw',
  height: '30rem'
}
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true

  // gestureHandling: 'greedy'
}
const center = {
  lat: 43.81153695965188,
  lng: 12.638450784616085
}
const GoogleApp = () => {
  const [marker] = useState(center)

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY ?? ''
  })

  if (loadError) return <div>Error..</div>
  if (!isLoaded) return <div>Loading</div>

  return (
    <div>
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={11}
        center={center}
        options={options}
      >
        <a href="https://www.google.com/maps/place/I+Calanchi+Agriturismo/@43.8113202,12.6362246,17z/data=!3m1!4b1!4m8!3m7!1s0x132cfa89f249e091:0x1c874a9947d4a221!5m2!4m1!1i2!8m2!3d43.8113202!4d12.6384186">
          <ModifiedButton>OPEN MAP</ModifiedButton>
        </a>
        <Marker key={1} position={marker}></Marker>
      </GoogleMap>
    </div>
  )
}

export default GoogleApp
