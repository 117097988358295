import { faCircle as regularCircle } from '@fortawesome/fontawesome-free-regular'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCircle } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { graphql } from 'gatsby'
import { useStaticQuery } from 'gatsby'
import { GatsbyImageFluidProps } from 'gatsby-image'
import React from 'react'
import makeCarousel from 'react-reveal/makeCarousel'
import Slide from 'react-reveal/Slide'
import { AppTheme } from 'src/styles/theme'
import styled from 'styled-components'
import { layout, LayoutProps, position, PositionProps } from 'styled-system'

import { ModifiedImg } from './ModifiedImg'

const query = graphql`
  query {
    agriturismo: file(relativePath: { eq: "agriturismo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fotocibo: file(relativePath: { eq: "fotocibo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fotocibo2: file(relativePath: { eq: "fotocibo2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    paesaggio: file(relativePath: { eq: "paesaggio.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    background: file(relativePath: { eq: "background.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

type ContainerProps = PositionProps<AppTheme> & LayoutProps<AppTheme>
const Container = styled.div<ContainerProps>`
  position: relative;
  overflow: hidden;
  ${layout}
  ${position}
`
const Dot = styled.span`
  cursor: pointer;
  user-select: none;
  position: relative;
  margin: 0 0.2rem;
  opacity: 0.8;
  left: 50%;
  z-index: 90;
`
const Dots = styled.div<LayoutProps>`
  display: flex;
  justify-content: center;
  ${layout}
  align-items: center;
  position: absolute;
  margin-bottom: 1rem;
  bottom: 0px;
`
const CarouselUI = ({ position, total, handleClick, children }: any) => (
  <Container
    height={['25rem', '47rem']}
    width={['100vw', '43rem']}
    right={[0, '3rem']}
  >
    {children}
    <Dots width={['50vw', '50%']}>
      {Array(...Array(total)).map((val, index) => (
        <Dot key={index} onClick={handleClick} data-position={index}>
          {index === position ? (
            <FontAwesomeIcon color="#8d363f" size="lg" icon={faCircle} />
          ) : (
            <FontAwesomeIcon
              color="#8d363f"
              size="lg"
              icon={regularCircle as IconProp}
            />
          )}
        </Dot>
      ))}
    </Dots>
  </Container>
)
const Carousel = makeCarousel(CarouselUI)

interface SlidingCarouselRightProps {
  images: GatsbyImageFluidProps[]
}
const SlidingCarouselRight: React.FC<SlidingCarouselRightProps> = ({
  images
}) => {
  return (
    <Carousel defaultWait={1000}>
      {images.map(image => (
        <Slide wait={5000} right>
          <ModifiedImg height={['25rem', '42rem']} fluid={image.fluid} />
        </Slide>
      ))}
    </Carousel>
  )
}

export default SlidingCarouselRight
