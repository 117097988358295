import React, { useRef, useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import IndexLayout from 'src/layouts'

import Landing from 'src/components/Landing'
import Page from 'src/components/Page'
import { ContentWrapper } from 'src/components/ContentWrapper'
import Progress from 'src/components/Progress'
import { useEventListener } from 'src/hooks/useEventListener'
import {
  faArrowUp,
  faBed,
  faCarrot,
  faHome,
  faPhone,
  faSeedling
} from '@fortawesome/free-solid-svg-icons'
import {
  faFacebook,
  faGoogle,
  faInstagram,
  faTripadvisor,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons'
import SlidingCarouselRight from 'src/components/SlidingCarouselRight'
import SlidingCarouselLeft from 'src/components/SlidingCarouselLeft'
import GoogleApp from 'src/components/GoogleApp'
import Argument from 'src/components/Argument'
import Space from 'src/components/Space'
import TitleOfContent from 'src/components/TitleOfContent'
import ModifiedImgWithAnimation from 'src/components/ModifiedImg'
import AbsoluteFontAwesomeIcon from 'src/components/AbsoluteFontAwesomeIcon'
import { window } from 'browser-monads'
import { useScreenSize } from 'src/hooks/useScreenSize'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useDataContext } from 'src/contexts/dataContext'
import styled from 'styled-components'
import Paragraph from 'src/components/Paragraph'
import { Footer } from 'src/components/Footer'
import MenuOverlay from 'src/components/MenuOverlay'
import MenuTest from 'src/components/MenuTest'

interface WhatsAppProps {
  isMobile: boolean
}
const WhatsApp = styled(FontAwesomeIcon)<WhatsAppProps>`
  position: fixed;
  z-index: 500;
  bottom: 0;
  margin: 2rem;
  left: ${props => (props.isMobile ? '' : '0')};
  right: ${props => (props.isMobile ? '0' : '')};
`

const query = graphql`
  query {
    cucina1: file(relativePath: { eq: "cucina1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cucina2: file(relativePath: { eq: "cucina2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cucina3: file(relativePath: { eq: "cucina3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cucina4: file(relativePath: { eq: "cucina4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cucina5: file(relativePath: { eq: "cucina5.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    cucina6: file(relativePath: { eq: "cucina6.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    camere1: file(relativePath: { eq: "camere1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    camere2: file(relativePath: { eq: "camere2.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    camere3: file(relativePath: { eq: "camere3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agr1: file(relativePath: { eq: "agr1.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agr2: file(relativePath: { eq: "agr2.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agr3: file(relativePath: { eq: "agr3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    agr4: file(relativePath: { eq: "agr4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ort1: file(relativePath: { eq: "ort1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ort2: file(relativePath: { eq: "ort2.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    full1: file(relativePath: { eq: "full1.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    full2: file(relativePath: { eq: "full2.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    full3: file(relativePath: { eq: "full3.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const IndexPage = () => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const [showIcon, setShowIcon] = useState(false)
  const [showWIcon, setShowWIcon] = useState(false)
  const { screenSize } = useScreenSize()
  const isMobile = screenSize === 'small' || screenSize === 'medium'
  const { getAllData, showMenu, language } = useDataContext()
  const { agricoltura, agriturismo, cucina, camere } = getAllData()

  const {
    ort1,
    ort2,
    cucina1,
    cucina2,
    cucina3,
    cucina4,
    cucina5,
    cucina6,
    camere1,
    camere2,
    camere3,
    agr1,
    agr2,
    agr3,
    agr4,
    full1,
    full2,
    full3
  } = useStaticQuery(query)
  const arrCucina = [
    cucina1.childImageSharp,
    cucina2.childImageSharp,
    cucina3.childImageSharp,
    cucina4.childImageSharp,
    cucina5.childImageSharp,
    cucina6.childImageSharp
  ]
  const arrAgriturismo = [
    agr1.childImageSharp,
    agr2.childImageSharp,
    agr3.childImageSharp,
    agr4.childImageSharp
  ]
  const arrOrto = [ort1.childImageSharp, ort2.childImageSharp]
  const arrCamere = [
    camere1.childImageSharp,
    camere2.childImageSharp,
    camere3.childImageSharp
  ]

  const getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    )
  }
  const progressAndIconToWork = () => {
    const scrollTop = window.pageYOffset
    const windowHeight = window.innerHeight
    const docHeight = getDocHeight()
    const totalDocScrollLength = docHeight - windowHeight
    const scrollPosition = Math.floor((scrollTop / totalDocScrollLength) * 100)
    scrollPosition < 20 ? setShowIcon(false) : setShowIcon(true)

    scrollPosition > 0 && scrollPosition > 90
      ? setShowWIcon(false)
      : setShowWIcon(true)

    setScrollPosition(scrollPosition)
  }
  useEventListener('scroll', progressAndIconToWork)

  return (
    <>
      <Progress scroll={`${scrollPosition}%`} />
      {!showMenu && showWIcon && (
        <a href="https://wa.me/+393474944789">
          <WhatsApp
            isMobile={isMobile}
            color="green"
            size="3x"
            icon={faWhatsapp}
          />
        </a>
      )}
      {showIcon && !isMobile && (
        <>
          <AbsoluteFontAwesomeIcon
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
            size="3x"
            color="black"
            icon={faArrowUp}
          />
        </>
      )}

      <IndexLayout>
        <Page>
          <Landing />
          {showMenu && <MenuOverlay />}
          <TitleOfContent
            id="home"
            icon={faHome}
            title={agriturismo.presentazione}
          />
          <ContentWrapper
            id="agriturismo"
            paddingX={[0, 4]}
            marginTop="3rem"
            flexDirection={['column', 'row']}
          >
            <SlidingCarouselLeft images={arrAgriturismo} />
            <Argument
              titleContent={agriturismo.titolo}
              paragraphContent={agriturismo.frase}
              mainContent={agriturismo.contenuto}
              extraContent="Che altro aggiungere..."
            />
          </ContentWrapper>

          {!isMobile && <Space heigth="5rem" />}
          <ModifiedImgWithAnimation image={full1.childImageSharp} />

          <TitleOfContent icon={faSeedling} title={agricoltura.presentazione} />
          <ContentWrapper
            id="agricoltura"
            paddingX={[0, 4]}
            marginTop="3rem"
            flexDirection={['column', 'row']}
          >
            <Argument
              titleContent={agricoltura.titolo}
              paragraphContent={agricoltura.frase}
              mainContent={agricoltura.contenuto}
            />
            <SlidingCarouselRight images={arrOrto} />
          </ContentWrapper>

          {!isMobile && <Space heigth="5rem" />}
          {!isMobile && (
            <ModifiedImgWithAnimation image={full3.childImageSharp} />
          )}

          <TitleOfContent icon={faCarrot} title={cucina.presentazione} />
          <ContentWrapper
            id="cucina"
            paddingX={[0, 4]}
            marginTop="3rem"
            flexDirection={['column', 'row']}
          >
            <SlidingCarouselLeft images={arrCucina} />
            <Argument
              titleContent={cucina.titolo}
              paragraphContent={cucina.frase}
              mainContent={
                language === 'ITA'
                  ? 'Materia prima buona, quasi sempre biologica, proveniente dalla nostra azienda e dalle altre aziende agricole che ci supportano,'
                  : 'Good raw material, almost always organic, coming from our company and from other farms that support us,'
              }
              extraContent={
                language === 'ITA'
                  ? 'abbinamenti semplici con il giusto condimento per gustare i sapori'
                  : 'simple combinations with the right seasoning to taste the flavors'
              }
              cucinaContent={
                language === 'ITA'
                  ? '  e per finire,il palato di chi crea gli abbinamenti,'
                  : 'and finally, the palate of those who create the combinations,'
              }
              footerContent={
                language === 'ITA'
                  ? ' questo è il segreto della nostra cucina.'
                  : 'this is the secret of our cuisine.'
              }
              extraFooterContent={
                language === 'ITA'
                  ? ' Tutto questo troverete nel nostro menù degustazione. '
                  : 'You will find all this in our tasting menu.f'
              }
            />
          </ContentWrapper>

          {!isMobile && <Space heigth="5rem" />}
          <ModifiedImgWithAnimation image={full2.childImageSharp} />

          <TitleOfContent icon={faBed} title={camere.presentazione} />
          <ContentWrapper
            id="camere"
            paddingX={[0, 6]}
            marginTop="3rem"
            flexDirection={['column', 'row']}
          >
            <Argument
              titleContent={camere.titolo}
              paragraphContent={camere.frase}
              mainContent={camere.contenuto}
            />
            <SlidingCarouselRight images={arrCamere} />
          </ContentWrapper>

          <ContentWrapper
            height={['30rem', '40rem']}
            flexDirection="column"
            id="dove-siamo"
          >
            <ContentWrapper
              borderBottom="2px solid red"
              flexDirection="column"
              width={['80%', '35%']}
            >
              <Paragraph
                fontSize={[3, 5]}
                fontStyle="titleFont"
                textAlign="center"
                margin="1rem"
              >
                Strada Provinciale per Borgo Massano 5, 61020 Montecalvo in
                foglia PU
              </Paragraph>
              <Paragraph
                fontSize={[3, 5]}
                fontStyle="titleFont"
                textAlign="center"
                margin="1rem"
              >
                <a href="mailto: info@icalanchiagriturismo.com">
                  info@icalanchiagriturismo.com
                </a>
              </Paragraph>
              <Paragraph
                fontSize={[3, 5]}
                fontStyle="titleFont"
                margin="1rem"
                marginBottom={['2rem', '4rem']}
                textAlign="center"
              >
                <FontAwesomeIcon color="negative" icon={faPhone} />
                +39 347 494 4789
              </Paragraph>
            </ContentWrapper>
            <ContentWrapper
              width="20rem"
              justifyContent="space-between"
              marginTop="1rem"
            >
              <a href="https://www.instagram.com/icalanchiagriturismo/?hl=en">
                <FontAwesomeIcon color="#f09433" size="3x" icon={faInstagram} />
              </a>
              <a href="https://wa.me/3474944789">
                <FontAwesomeIcon color="green" size="3x" icon={faWhatsapp} />
              </a>
              <a href="https://www.tripadvisor.it/Restaurant_Review-g734480-d2184548-Reviews-I_Calanchi_Agriturismo-Montecalvo_in_Foglia_Province_of_Pesaro_and_Urbino_Marche.html">
                <FontAwesomeIcon color="black" size="3x" icon={faTripadvisor} />
              </a>
              <a href="https://www.facebook.com/I-Calanchi-Agriturismo-200876209962500/">
                <FontAwesomeIcon color="blue" size="3x" icon={faFacebook} />
              </a>
              <a href="https://www.google.com/travel/hotels/entity/CgoIocTSvpTT0sMcEAE/reviews?g2lb=2502548%2C4258168%2C4270442%2C4306835%2C4317915%2C4328159%2C4371334%2C4401769%2C4419364%2C4463263%2C4463666%2C4482194%2C4482438%2C4486153%2C4491350%2C4495816%2C4496891%2C4270859%2C4284970%2C4291517&hl=en-IT&gl=it&un=1&rp=EKHE0r6U09LDHBChxNK-lNPSwxw4AkAASAHAAQI&ictx=1&ved=0CAAQ5JsGahcKEwiwk7mtnN_uAhUAAAAAHQAAAAAQCQ&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESABpJCisSJzIlMHgxMzJjZmE4OWYyNDllMDkxOjB4MWM4NzRhOTk0N2Q0YTIyMRoAEhoSFAoHCOUPEAIYCxIHCOUPEAIYDBgBMgIQACoECgAaAA">
                <FontAwesomeIcon color="red" size="3x" icon={faGoogle} />
              </a>
            </ContentWrapper>
          </ContentWrapper>

          <ContentWrapper
            width="100%"
            height="30rem"
            flexWrap="wrap"
            flexDirection={['row', 'column']}
          >
            <GoogleApp />
          </ContentWrapper>
          <Footer />
        </Page>
      </IndexLayout>
    </>
  )
}

export default IndexPage
