import styled from 'styled-components'

type ProgressProps = {
  scroll: string
}

const Progress = styled.div<ProgressProps>`
  position: fixed;
  top: 0;
  background: linear-gradient(
    to right,
    rgba(250, 224, 66, 0.8) ${props => props.scroll},
    transparent 0
  );
  width: 100%;
  height: 4px;
  z-index: 300;
`

export default Progress
